import React, {useState} from "react";
import ProgressButton from "./ProgressButtons";
import ImageGridSelector from "./ImageGridSelector";

function PersonalitySelector({onNext=null,
    onPrevious=null,
    onComplete=null,
    personalityOptions=[{value:"ambitious"},{value:"cheerful"},{value:"childish"},{value:"clumsy"},{value:"creative"},{value:"erratic"},{value:"genius"},{value:"gloomy"},{value:"goofball"},{value:"high maintenance"},{value:"hot headed"},{value:"paranoid"},{value:"romantic"},{value:"self-assured"},{value:"squeamish"},{value:"wise"}],
    lookup={}


    }){
    const [personalitySelection,setPersonalitySelection] = useState(lookup.personality)
    const [progressAttempt, setProgressAttempt] = useState(false)

    const isDataGood = ()=>{

        return Boolean(personalitySelection);

    }

    const handleNext = onNext?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onNext({personality:personalitySelection})
        }}:null



    const handlePrevious =  onPrevious?()=>{

        onPrevious({personality:personalitySelection})



    }:null
    const handleDone = onComplete?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onComplete({personality:personalitySelection})
        }

    }:null
    return(
        <div>
            <ImageGridSelector onChange={(e)=>setPersonalitySelection(
                                e.currentTarget.value
                                )}
                                selectorName="Personality"
                                flagHelp={!personalitySelection&&progressAttempt}
                                attributeOptions={personalityOptions}
                                value={personalitySelection}
                                />



        <ProgressButton onPrevious={handlePrevious} onNext={handleNext} onComplete={handleDone}/>
        </div>
    )

}
export default PersonalitySelector;
