import React from "react";
import ProgressButton from "./ProgressButtons";

function SummaryScreen({ onNext = null, onPrevious = null, onComplete = null, lookup = {} }) {
  const handleNext = onNext
    ? () => {
        onNext({});
      }
    : null;

  const handlePrevious = onPrevious
    ? () => {
        onPrevious({});
      }
    : null;

  const handleDone = onComplete
    ? () => {
        onComplete({});
      }
    : null;

  return (
    <div className="max-w-md mx-auto mt-8 p-4 border rounded bg-white">
      <ul className="list-disc pl-4 mb-4">
        {Object.keys(lookup).map((k) => (
          lookup[k]?<li key={k} className="mb-2">
            <span className="font-semibold">{k}:</span> {lookup[k]}
          </li>:null
        ))}
      </ul>
      <ProgressButton onPrevious={handlePrevious} onNext={handleNext} onComplete={handleDone} />
    </div>
  );
}

export default SummaryScreen;
