import React, {useState} from "react";
import ImageGridSelector from "./ImageGridSelector";
import ProgressButton from "./ProgressButtons";

function BaseProfileConfigSelector({
    onNext=null,
    onPrevious=null,
    onComplete=null,
    localChatOptions=[
        {value:false, display:"Allow Third-Party Chat Services", hint:"Conversations from this profile will never be farmed out to third-party hosted LLMs"},
        {value:true, display:"Only Generate with Controlled LLMS", hint:"Conversations from this profile may be farmed out to third-party hosted LLMs"},
    ],
    profileContentOptions=[
        {value:false, display:"Safe for All ☮️", hint:"Conversations with this profile may be censored"},
        {value:true, display:"Saucy 🌶️(18+)", hint:"Conversations from this profile may include profanity, sexual descriptions, etc. "},
    ],

    lookup={}
}){
    const [localChatSelection, setLocalChatSelection] = useState(lookup.local_chat_only?lookup.local_chat_only:false)
    const [nsfwModelSelection, setNSFWModel] = useState(lookup.nsfw_model?lookup.nsfw_model:false)
    const [progressAttempt, setProgressAttempt] = useState(false)

    const isDataGood = ()=>{
        // In theory, as we are setting defaults for both of this, this should always be true
        return localChatSelection!=null &&nsfwModelSelection!=null ;
    }

    const handleNext = onNext?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            if(localChatSelection){
                if(window.confirm("Warning! Local Chat Only configuration is available at all times and you may experience delays in chat/image generation. Third party chat relay is anonymous and strongly encourage for typical use during this stage of development")){
                    onNext({
                    local_chat_only:localChatSelection,
                    nsfw_model:nsfwModelSelection

                })

            }

            }else{
                onNext({
                    local_chat_only:localChatSelection,
                    nsfw_model:nsfwModelSelection

                })

            }

        }}:null



    const handlePrevious =  onPrevious?()=>{

        onPrevious({
            local_chat_only:localChatSelection,
            nsfw_model:nsfwModelSelection

        })}:null

    const handleDone = onComplete?()=>{
        if(isDataGood()){
            onComplete({
                local_chat_only:localChatSelection,
                nsfw_model:nsfwModelSelection

            })
        }
    }:null

    return(<div>
        <ImageGridSelector onChange={(e)=>setLocalChatSelection(
                                e.currentTarget.value==='true'
                                )}
                                selectorName="Chat Generation Constraint"
                                flagHelp={(localChatSelection!=null)&&progressAttempt}
                                attributeOptions={localChatOptions}
                                value={localChatSelection}
                                description="Defines the conditions under which chat is generated. Regardless of selection, all chats are private."
                                />

        <ImageGridSelector onChange={(e)=>setNSFWModel( e.currentTarget.value==='true' )}
                                selectorName="Profile Content"
                                flagHelp={(nsfwModelSelection!=null)&&progressAttempt}
                                attributeOptions={profileContentOptions}
                                value={nsfwModelSelection}
                                description="The general level of filters and safeties applied to your interactions"
                                />

        <ProgressButton onPrevious={handlePrevious} onNext={handleNext} onComplete={handleDone}/>

        </div>

    )


}

export default BaseProfileConfigSelector;
