import React from "react";
import { useAuth } from "react-oidc-context";

function UserProfile({className}){
    const auth = useAuth();
    return (
    <div
      onClick={() => (auth.isAuthenticated ? void auth.removeUser() : void auth.signinRedirect())}
      className={`cursor-pointer ${className}`}
    >
      <div className="text-white hover:text-gray-300 font-medium">
        {auth.isAuthenticated ? 'Log Out' : 'Log In'}
      </div>
    </div>
  );

};

export default UserProfile;
