import React, {useState} from "react";
import ProgressBar from "../components/aicreation/ProgressBar";
import StyleSelection from "../components/aicreation/StyleSelection";
import PhysicalAppearanceSelection from "../components/aicreation/PhysicalAppearanceSelection";
import HairSelection from "../components/aicreation/HairSelection";
import TBASelection from "../components/aicreation/TBASelection";
import PersonalitySelector from "../components/aicreation/PersonalitySelector";
import OccupationHobbySelector from "../components/aicreation/OccupationHobbySelector";
import FavoriteClothingSelector from "../components/aicreation/FavoriteOutfitSelector";
import SummaryScreen from "../components/aicreation/SummaryScreen";
import { useAuth } from "react-oidc-context";
import NameGenderSelector from "../components/aicreation/NameGenderSelector";
import BaseProfileConfigSelector from "../components/aicreation/BaseProfileConfigSelector";
import FacialHairSelection from "../components/aicreation/FacialHairSelection";
import EndowmentSelection from "../components/aicreation/EndowmentSelection";
import { useCookies } from 'react-cookie';

function AICreationScreen(){
    const auth = useAuth();
    const workflow = [
        {component:BaseProfileConfigSelector, conditions:[], provides:["local_chat_only","nsfw_model"]},
        {component:NameGenderSelector, conditions:[], provides:["name","gender"]},
        {component:StyleSelection, conditions:[], provides:["style"]},
        {component:PhysicalAppearanceSelection, conditions:[], provides:["ethnicity", "age", "eyes"]},
        {component:HairSelection, conditions:[], provides:["hair_style", "hair_color"]},
        {component:FacialHairSelection, conditions:[{key:"gender",value:"male"}], provides:["facial_hair_style","facial_hair_color"]},
        {component:TBASelection, conditions:[{key:"nsfw_model", value:true},{key:"gender",value:"female"}], provides:["breast","body","butt"]},
        {component:EndowmentSelection, conditions:[{key:"nsfw_model", value:true},{key:"gender",value:"male"}], provides:["penis","body","butt"]},
        {component:PersonalitySelector, conditions:[], provides:["personality"]},
        {component:OccupationHobbySelector,conditions:[], provides:["hobbies","occupation"]},
        {component:FavoriteClothingSelector,conditions:[], provides:["clothing"]},
        {component:SummaryScreen, conditions:[], provides:[]}
    ]
    // Accepts an ordered list of
    const [cookies, setCookies] = useCookies(["attributes"]);
    const [workflowPosition,setWorkflowPosition] = useState(0);
    const workflowLength = workflow.length;
    const removeFromCookies = (attributesToRemove=[], state) =>{
        attributesToRemove.forEach((val)=>{
            delete state[val]
        })
        return state

    }
    const getNextWorkflowPosition = (pos=0,backwards=false, state={})=> {
        for(const cond of workflow[pos].conditions){
            if(cookies.attributes[cond.key] !== cond.value){
                // We skip this one and remove any contributions it may have previously made
                removeFromCookies(workflow[pos].provides, state)
                pos = getNextWorkflowPosition(backwards?pos-1:pos+1, backwards)
                break
            }
        }
        return pos



    }
    const handleNext = (data)=>{
        let tmp = {...cookies.attributes,...data}

        setWorkflowPosition(
                getNextWorkflowPosition(workflowPosition+1,false,tmp)
            );
        setCookies("attributes", tmp);
    };
    const handlePrevious = (data)=>{
        let tmp = {...cookies.attributes,...data};
        setWorkflowPosition(
            getNextWorkflowPosition(workflowPosition-1,true, tmp)
        );
        setCookies("attributes", tmp)
    }
    const submitAICreation = async () =>{

        if(auth.isAuthenticated){
            try{
                const token = auth.user?.access_token
                const name = cookies.attributes.name?cookies.attributes.name:"test name"
                delete cookies.attributes.name
                const local_chat_only = cookies.attributes.local_chat_only
                delete cookies.attributes.local_chat_only
                const nsfw_model = cookies.attributes.nsfw_model
                delete cookies.attributes.nsfw_model

                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_BASE}/profiles`,{
                        method:"POST",
                        headers:{
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({attributes:cookies.attributes,name:name,local_chat_only:local_chat_only,nsfw_model:nsfw_model})
                    }

                );
                if(response.status === 201){
                    setCookies("attributes",{})
                    window.location.replace("/profiles")
                }
                else{
                    window.alert("Error Creating Profile. Try again soon")
                }


            } catch(e){
                console.error(e);
            }

        }else{
            alert("Register to finish character creation!")
        }

    }
    const handleComplete = (data)=>{
        setCookies(
            {...cookies.attributes,...data}
        );

        // submit request
        submitAICreation()

    };


    const CurrentStepComponent = workflow[workflowPosition].component;



    return (
        <div>
            <h1>Create you AI</h1>
            <ProgressBar progress={Math.round(100*workflowPosition/workflowLength)}/>
            <CurrentStepComponent lookup={cookies.attributes} onNext={ workflowPosition < (workflowLength-1) ?  handleNext:null} onPrevious={ workflowPosition>0 ?  handlePrevious:null} onComplete={ workflowPosition===(workflowLength-1) ?  handleComplete:null}/>
        </div>
    )



}


export default AICreationScreen
