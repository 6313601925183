import React from "react";
import {Outlet} from "react-router-dom";
import NavBar from '../components/NavBar';
import FooterComponent from "../components/FooterComponent";
function Layout() {
  return (
      <div className="flex flex-col max-height-screen">
          {/* Navbar */}
          <NavBar className="flex-none bg-gray-800" />

          {/* Content Area */}
          <div className="flex-grow pt-5 pb-5">
              <Outlet />
          </div>

          {/* Footer */}
          <FooterComponent className="flex-none" />
      </div>
  );
}

export default Layout;
