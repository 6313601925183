import React  from "react";
import styles from "../../styles/RangeSelector.module.css"

function RangeSelector({onChange, name, value, flagHelp=false, minVal, maxVal}){

    return(
        <div className={styles.RangeSelectorContainer}>
        <h2>{name}</h2>
        <div>
        <input aria-label={name} type="range" name={name} min={minVal} max={maxVal} value={value} onChange={(e)=>{onChange(e.currentTarget.value)}} />
        <p>{value}</p>
        </div>
        {flagHelp && <p>Please select an option</p>}
        </div>

    )
}

export default RangeSelector
