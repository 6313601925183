import React, {useState} from "react";
import ChatBarProfile from "./ChatBarProfile";
import placeholder_image from '../../resources/create_character_image.png'
import SearchBar from "../SearchBar";
function ChatBar({profiles=[],onSelect, className }){
    const [searchBarContents, setSearchBarContents] = useState(null);
    return (
        <div className={`${className} flex-col`}>
          <SearchBar
                onChange={(val) => {
                  setSearchBarContents(val.currentTarget.value);
                }}
                textContent={searchBarContents}
                searchBarContainerStyle="bg-gray-100 p-2 rounded"
              />
          <ul className="overflow-y-scroll">

            {profiles
              .filter((val) =>
                searchBarContents
                  ? val.name.toLowerCase().includes(searchBarContents)
                  : true
              )
              .map((val) => {
                const profile_image =
                  val.profile_images.length > 0
                    ? val.profile_images[0].image_url
                    : placeholder_image;

                return (
                  <li key={val.id} className="mt-2">
                    <ChatBarProfile
                      profileImage={profile_image}
                      profileName={val.name}
                      onClick={() => {
                        onSelect(val.id);
                      }}
                    />
                  </li>
                );
              })}
          </ul>
        </div>
      );


};


export default ChatBar;
