import { useEffect, useState } from "react";
import React from "react";
import ChatBar from "../components/chat/ChatBar";
import IndividualChatWindow from "../components/chat/IndividualChatWindow";
import { useAuth } from "react-oidc-context";
import { useParams } from 'react-router-dom'

function ChatView(){
    const auth = useAuth();
    const [profiles, setProfiles] = useState([])
    const { profileID } = useParams()
    const [selectedProfile, setSelectedProfile] = useState(profileID)

    useEffect(()=>{
        const getProfiles = async ()=>{
        if(auth.isAuthenticated){
            try{
            const token = auth.user?.access_token
            const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE}/profiles`,{
                method: "GET",
                headers:{
                    Authorization: `Bearer ${token}`,
                }
            });
            if(response.status === 200){
                setProfiles(await response.json());
            }else{
                setProfiles([])
            }

        } catch (e){
            console.error(e);
        }
    }
    };
    getProfiles();
    },[auth.isAuthenticated, auth.user?.access_token]);
return (
  <div className="max-h-screen flex">
    <ChatBar profiles={profiles} onSelect={(e) => { setSelectedProfile(e) }} className="hidden md:flex  bg-gray-200 border-r border-gray-300 overflow-hidden border-2 rounded w-1/6"/>

    <IndividualChatWindow className="flex p-4 md:w-5/6 flex-col" profileID={selectedProfile} />
  </div>

);

}

export default ChatView;
