import React,{useEffect, useState} from "react";
import { useAuth } from "react-oidc-context";
function SettingsPage(){
    const auth = useAuth();
    const [userInfo, setUserInfo] = useState({})
    const [billingURL, setBillingURL] = useState({})
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        const getUserInfo = async ()=>{
            if(auth){
                const token = auth.user?.access_token
                const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE}/users`,{
                    method: "GET",
                    headers:{
                        Authorization: `Bearer ${token}`,
                    }
                });
                if(response.status === 200){
                    setUserInfo(await response.json());
                    setLoading(false)
                }else{
                    setUserInfo(null)
                }
            }
        }
        getUserInfo();
    }, [auth])
    useEffect(()=>{
        const getBillingUrl = async ()=>{
            if(auth){
                const token = auth.user?.access_token
                try{
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE}/payment/stripe-billing-portal`,{
                        method: "GET",
                        headers:{
                            Authorization: `Bearer ${token}`,
                        }
                    })
                    if(response.status === 200){
                        setBillingURL(await response.json());
                    }else{
                        setBillingURL(null)
                    }

                }catch(reason){
                    console.log("Error grabbing billing portal information")
                    console.log(reason)
                    setBillingURL(null);
                }


            }
        }
        getBillingUrl();
    }, [auth])
    const subscriptionExpired = ()=>{
        if(userInfo.subscription_expires_at === null){
            return true
        }else{

            return new Date(Number(userInfo.subscription_expires_at)*1000) <= new Date()
        }

    }
    const getSubscriptionExpiresDateString = (expiresOn)=>{
        const expire_date = new Date(Number(expiresOn)*1000)
        return expire_date.toLocaleString('default', {weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric", });
    }

    return (
        <div className="p-4 bg-gray-100"> {/* Use appropriate padding and background color classes */}
          {loading ? (
            <div className="text-center text-gray-700">Loading...</div>
          ) : (
            <>
              <div>
                <h2 className="text-xl font-bold mb-2">Account Info</h2>
                <p>
                  <b>Account ID:</b> {userInfo.id}
                </p>
                <p>
                  <b>Stripe ID:</b> {userInfo.stripe_id}
                </p>
              </div>
              <div className="mt-4">
                <h2 className="text-xl font-bold mb-2">AI Usage</h2>
                <p>
                  <b>Tokens Remaining:</b> {userInfo.tokens_remaining}
                </p>
                <p>
                  <b>Tokens Used:</b> {userInfo.tokens_used}
                </p>
                <p>
                  <b>Images Generated:</b> {userInfo.pictures_generated}
                </p>
                <h2 className="text-xl font-bold mt-4">Subscription</h2>
                <p>
                  <b>Status:</b> {subscriptionExpired() ? 'Inactive' : 'Active'}
                </p>
                {userInfo.subscription_expires_at != null && (
                  <p>
                    <b>Expires On:</b>{' '}
                    {getSubscriptionExpiresDateString(userInfo.subscription_expires_at)}
                  </p>
                )}
              </div>

              <div className="mt-4 flex justify-center">
                {subscriptionExpired() ? (
                  <form action="/payment">
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      type="submit"
                    >
                      Subscribe
                    </button>
                  </form>
                ) : (
                  <form action={billingURL}>
                    <button
                      className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                      type="submit"
                    >
                      Manage Billing
                    </button>
                  </form>
                )}
              </div>
            </>
          )}
        </div>
      );
}

export default SettingsPage;
