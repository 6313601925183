import React, {useState} from "react";
import ProgressButton from "./ProgressButtons";
import ImageGridSelector from "./ImageGridSelector";
function StyleSelection({onNext=null, onPrevious=null, onComplete=null,lookup={}, ai_styles=[{value:"realistic",image_src:""},{value:"anime",image_src:""},{value:"rendered ",image_src:""}]}){
    const [styleSelection,setStyleSelection] = useState(lookup.style)
    const [progressAttempt, setProgressAttempt] = useState(false)
    const isDataGood = ()=>{
        return styleSelection != null;

    }
    const handleNext = onNext?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onNext({style:styleSelection})
        }}:null



    const handlePrevious =  onPrevious?()=>{

            onPrevious({style:styleSelection})



    }:null
    const handleDone = onComplete?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onComplete({style:styleSelection})
        }

    }:null
    return(
        <div>
            <ImageGridSelector onChange={(e)=>setStyleSelection(
                                e.currentTarget.value
                                )}
                                selectorName="Style"
                                description="This impacts the general style of images that are created for your AI"
                                flagHelp={(!isDataGood())&&progressAttempt}
                                attributeOptions={ai_styles}
                                value={styleSelection}
                                />


        <ProgressButton onPrevious={handlePrevious} onNext={handleNext} onComplete={handleDone}/>
        </div>
    )

}
export default StyleSelection;
