import React, {useState} from "react"
import TextInput from "./TextEntry"
import ImageGridSelector from "./ImageGridSelector"
import ProgressButton from "./ProgressButtons"

function NameGenderSelector({onNext=null,
    onPrevious=null,
    onComplete=null,
    genderOptions=[{value:"male",image_src:""},{value:"female",image_src:""}],
    nameMaxLength=255,
    lookup={}
    }){
    const [genderSelection,setGenderSelection] = useState(lookup.gender)
    const [nameEntry,setName] = useState(lookup.name?lookup.name:"")

    const [progressAttempt, setProgressAttempt] = useState(false)

    const isDataGood = ()=>{
        return genderSelection&&nameEntry;
    }

    const handleNext = onNext?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onNext({gender:genderSelection,name:nameEntry})
        }}:null



    const handlePrevious =  onPrevious?()=>{
        onPrevious({gender:genderSelection,name:nameEntry})
    }:null
    const handleDone = onComplete?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onComplete({gender:genderSelection,name:nameEntry})
        }

    }:null
    return(
        <div>
            <ImageGridSelector onChange={(e)=>setGenderSelection(
                                e.currentTarget.value
                                )}
                                selectorName="Gender"
                                flagHelp={!genderSelection&&progressAttempt}
                                attributeOptions={genderOptions}
                                value={genderSelection}
                                />

            <TextInput onChange={(e)=>(

                setName(e)
            )} textContent={nameEntry} selectorName="AI Name" flagHelp={!nameEntry.length>0&&progressAttempt}/>

        <ProgressButton onPrevious={handlePrevious} onNext={handleNext} onComplete={handleDone}/>
        </div>
    )

}
export default NameGenderSelector;
