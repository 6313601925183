import React, { useState, useEffect, useContext, useCallback } from 'react';
import { GetObjectCommand } from '@aws-sdk/client-s3';
import { S3ClientContext } from './S3ClientComponent';
import image_placeholder from '../resources/create_character_image.png'


async function getReadableStream(response) {
  const reader = response.Body.getReader();
  return new ReadableStream({
    start(controller) {
      return pump();
      function pump() {
        return reader.read().then(({ done, value }) => {
          // When no more data needs to be consumed, close the stream
          if (done) {
            controller.close();
            return;
          }
          // Enqueue the next data chunk into our target stream
          controller.enqueue(value);
          return pump();
        });
      }
    },
  });

}

const AuthenticatedImage = ({ imageUrl, backupImage = image_placeholder, imgClass, onClick = null, alt = "fetched", onTouchStart=null, style={}}) => {
  // Provides a component for handling calling s3, caching and presenting usable images
  const s3Client = useContext(S3ClientContext)
  const [imageData, setImageData] = useState(backupImage);

  const fetchImage = useCallback(async () => {

    //If we have a client and a image url was provided
    if (s3Client && imageUrl) {
      //Check cache to see if we've see this url before
      const cacheData = sessionStorage.getItem(imageUrl)
      if (cacheData == null) {
        try {
          const url = new URL(imageUrl)
          const { pathname } = url;
          const [, bucketName, ...keyPath] = pathname.split("/")

          const command = new GetObjectCommand({
            Bucket: bucketName,
            Key: keyPath.join("/"),
          }

          )
          const response = await s3Client.send(command);
          const stream = new Response(await getReadableStream(response))
          const blob = await stream.blob();

          const urlObject = URL.createObjectURL(blob);

          setImageData(urlObject);
          sessionStorage.setItem(imageUrl, urlObject);


        } catch (e) {
          console.log(e.fault)
          console.log(e)
          setImageData(backupImage)
        }

      } else {
        setImageData(cacheData)
      }

    }
  }, [s3Client, imageUrl, backupImage])
  useEffect(() => {
    // Fetch image data with authorization header
    fetchImage()

    // Clean up if needed
    return () => {

    };
  }, [fetchImage, s3Client, imageData]);
  const cleanse = () => {
    URL.revokeObjectURL(imageData);
    sessionStorage.removeItem(imageUrl, null)
    setImageData(backupImage)
  }
  return (
    <img src={imageData} alt={alt} className={imgClass} onClick={onClick} onError={cleanse} onTouchStart={onTouchStart} style={style}/>
  );
};

export default AuthenticatedImage;
