import React, {useState} from "react";
import ImageGridSelector from "./ImageGridSelector";
import ProgressButton from "./ProgressButtons";


function TBASelection(
    {onNext=null,
    onPrevious=null,
    onComplete=null,
    breastOptions=[{value:"flat",image_src:""},{value:"small",image_src:""},{value:"medium",image_src:""},{value:"large ",image_src:""},{value:"huge",image_src:""}],
    bodyOptions= [{value:"petite",image_src:""},{value:"slim",image_src:""},{value:"athletic ",image_src:""},{value:"voluptuous",image_src:""},{value:"curvy",image_src:""}],
    buttOptions= [{value:"small",image_src:""},{value:"medium",image_src:""},{value:"large ",image_src:""},{value:"skinny",image_src:""},{value:"athletic",image_src:""}],
    lookup={}


    }
){
    const [breastSelection,setBreastSelection] = useState(lookup.breast)
    const [bodySelection,setBodySelection] = useState(lookup.body)
    const [buttSelection,setButtSelection] = useState(lookup.butt)
    const [progressAttempt, setProgressAttempt] = useState(false)

    const isDataGood = ()=>{
        return breastSelection&&bodySelection&&buttSelection;

    }
    const handleNext = onNext?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onNext({breast:breastSelection,body:bodySelection,butt:buttSelection})
        }}:null



    const handlePrevious =  onPrevious?()=>{

        onPrevious({breast:breastSelection,body:bodySelection,butt:buttSelection})



    }:null
    const handleDone = onComplete?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onComplete({breast:breastSelection,body:bodySelection,butt:buttSelection})
        }

    }:null

    return(
        <div>
            <ImageGridSelector onChange={(e)=>setBodySelection(
                                e.currentTarget.value
                                )}
                                selectorName="Body Type"
                                flagHelp={!bodySelection&&progressAttempt}
                                attributeOptions={bodyOptions}
                                value={bodySelection}
                                />
            <ImageGridSelector onChange={(e)=>setBreastSelection(
                                e.currentTarget.value
                                )}
                                selectorName="Breast Type"
                                flagHelp={!breastSelection&&progressAttempt}
                                attributeOptions={breastOptions}
                                value={breastSelection}
                                />
            <ImageGridSelector onChange={(e)=>setButtSelection(
                                e.currentTarget.value
                                )}
                                selectorName="Butt Type"
                                flagHelp={!buttSelection&&progressAttempt}
                                attributeOptions={buttOptions}
                                value={buttSelection}
                                />

        <ProgressButton onPrevious={handlePrevious} onNext={handleNext} onComplete={handleDone}/>
        </div>
    )

}
export default TBASelection;
