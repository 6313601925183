import React from "react";
function ImageGridSelector({ onChange, selectorName, value = null, flagHelp = false, attributeOptions = [], description=null }) {
    return (
      <div>
        <h2 className="text-lg font-semibold mb-2">{selectorName}</h2>
        {description && <p className="text-gray-600 mb-4 italic">{description}</p>}
        <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 ">
        {attributeOptions.map((item) => (
          <li
            className={`${value === item.value ? 'border-blue-500 bg-blue-200' : 'bg-gray-100 border-gray-300'} ${
              value === item.value ? 'shadow-md' : ''
            }`}
            key={item.value}
            title={item.hint}
          >
            <label className="relative flex place-content-center p-4 border rounded cursor-pointer transition duration-300 transform hover:scale-105">
              <input
                className="absolute top-0 left-0 opacity-0 cursor-pointer"
                type="radio"
                name={selectorName}
                id={`${item.value}${selectorName.trim()}`}
                value={item.value}
                onChange={onChange}
                checked={value === item.value}
              />
              <div className="flex flex-col items-center">
                {item.image_src && (
                  <img className="w-16 h-16 object-cover rounded-full mb-2" src={item.image_src} alt={item.value} />
                )}
                <div className="text-center font-medium">{item.value}</div>
                {item.display && <div className="text-sm text-gray-500">{item.display}</div>}
              </div>
            </label>
          </li>

        ))}
        </ul>
        {flagHelp && <p className="mt-2 text-red-500">Please select an option</p>}
      </div>
    );
  }



export default ImageGridSelector
