import React from "react";
function TextInput({ onChange, textContent, selectorName, rejectRegex = /[^a-z^ ^0-9]/gi, replaceWith = "", flagHelp = false }) {
  const handleChange = (e) => {
    onChange(e.target.value.replace(rejectRegex, replaceWith));
  };

  return (
    <div>
      <h2 className="text-lg font-semibold mb-2">{selectorName}</h2>
      <input
        className={`border rounded p-2 bg-gray-100`}
        id="ChatInput input-field"
        type="text"
        placeholder="John Doe"
        value={textContent ? textContent : ""}
        onChange={handleChange}
        autoComplete="off"
      />
      {flagHelp && <p className="mt-2 text-red-500">Need to fill this out!</p>}
    </div>
  );
}

export default TextInput;
