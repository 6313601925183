import React, { useState } from "react";
import AuthenticatedImage from "../AuthenticatedImage";

function ChatImages({ images = [] }) {
  const [currentPos, setCurrentPos] = useState(0);

  const handleSwipe = (direction) => {
    if (direction === "left" && currentPos < images.length - 1) {
      setCurrentPos(currentPos + 1);
    } else if (direction === "right" && currentPos > 0) {
      setCurrentPos(currentPos - 1);
    }
  };

  const handleClick = (newPos) => {
    setCurrentPos(newPos);
  };

  return (
    <div className="relative flex items-center justify-center">
      {currentPos < images.length - 1 && (<div
        className="top-1/2 transform -translate-y-1/2 cursor-pointer"
        onClick={() => handleSwipe("left")}
      >
        {/* Left Arrow */}
        {/* You can replace this with your own arrow component or icon */}
        <span class="material-symbols-outlined">arrow_back</span>
      </div>)
}


      <AuthenticatedImage
        imageUrl={images[currentPos].image_url}
        imgClass="cursor-pointer fit object-fit:contain md:w-1/3 "
        onClick={() => handleClick((currentPos + 1) % images.length)}
      />
      {currentPos > 0 && (<div
        className="top-1/2 transform -translate-y-1/2 cursor-pointer"
        onClick={() => handleSwipe("right")}
      >
        <span class="material-symbols-outlined">arrow_forward</span>

      </div>)
    }
    </div>
  );
}

export default ChatImages;
