import React,{useEffect, useState} from "react";
import ProfileCard from "../components/ProfileCard";
import create_image from '../resources/create_character_image.png'
import { useCookies } from 'react-cookie';
import { useAuth } from "react-oidc-context";
import SearchBar from "../components/SearchBar";



function ProfileView(){
    const auth = useAuth();
    const [, setCookies] = useCookies(["attributes"]);
    const [searchBarContents, setSearchBarContents] = useState(null);
    const [profiles, setProfiles] = useState([])
    const createAIRedirect = ()=>{
        window.location.replace("createai")
    }
    const cloneProfile = (profile)=>{
        setCookies("attributes",{
            name:profile.name,
            local_chat_only:profile.local_chat_only,
            nsfw_model:profile.nsfw_model,
            profile_privacy:profile.profile_privacy,
            ...profile.attributes
        })
        window.location.replace("/createai")
    }
    const deleteProfile = async (profileID)=>{
        if (window.confirm("Are you sure you want to delete this item?")) {
            // User clicked OK
            try{
                const token = auth.user?.access_token
                const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE}/profiles/${profileID}`,{
                    method: "DELETE",
                    headers:{
                        Authorization: `Bearer ${token}`,
                    }
                });
                if(response.status !== 201){
                    window.alert("Problem deleting profile! Try again soon")
                }
                window.location.replace("/profiles")
            } catch (e){
                console.error(e);

            }
            return;
          } else {

            return;
          }
    }
    useEffect(()=>{
            const getProfiles = async ()=>{
            if(auth.isAuthenticated){
                try{
                const token = auth.user?.access_token
                const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE}/profiles`,{
                    method: "GET",
                    headers:{
                        Authorization: `Bearer ${token}`,
                    }
                });
                if(response.status === 200){
                    setProfiles(await response.json());
                }else{
                    setProfiles([])
                }


            } catch (e){
                console.error(e);
                setProfiles([])
            }
        }
        };
    getProfiles();
    },[auth.isAuthenticated, auth.user]);

    return (
        <div className="pt-4">
        <SearchBar onChange={(val)=>{
        setSearchBarContents(val.currentTarget.value)
        }} textContent={searchBarContents} searchBarContainerStyle="bg-gray-100 p-4 rounded" searchGlass={true}/>
        <ul  className="place-content-center lg:gap-4 lg:grid-cols-4 md:grid md:grid-cols-3 flex flex-col items-center space-y-4 justify-center">
            <ProfileCard name="Create New AI" image={create_image} onClick={createAIRedirect} key="create-new-ai" image_requires_auth={false}/>
            {profiles?profiles.filter((val)=>{return searchBarContents?val.name.toLowerCase().includes(searchBarContents):true}).map(
                (val) =>{

                    return <ProfileCard
                                image={val.profile_images.length>0?val.profile_images[0].image_url:null}
                                image_requires_auth={true}
                                name={val.name} attributes={val.attributes}
                                profileID={val.id} key={val.id}
                                onDeleteClick={()=>{
                                    deleteProfile(val.id)
                                }}
                                onCloneClick={()=>{
                                    cloneProfile(val)
                                }}
                                onPhotoClick={()=>{window.alert("Not Implemented Yet!")}}
                                onVitalsClick={()=>{window.alert("Not Implemented Yet!")}}
                                />

                }
            ):null}

        </ul>
        </div>
    )


}

export default ProfileView;
