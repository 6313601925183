import React from "react";
import ChatImages from "./ChatImages";

function AIChatEntry({
  chatMessage,
  takePicture = null,
  alertChat = null,
  dislikeChat = null,
  likeChat = null,
}) {
  const createdAt = new Date(chatMessage.created_on);

  return (
    <div className={'border bg-gray-100 border-gray-300 rounded p-4 mb-4 relative flex flex-col min-h-50 w-11/12'} key={chatMessage.id}>
      <span onClick={alertChat} className="material-symbols-outlined  absolute top-0 right-0" title="Alert">warning</span>
      <div className="flex pt-2">
        <p className={''}>{chatMessage.content}</p>
      </div>

      <div className="mt-2 flex justify-between">
        <span onClick={() => takePicture(chatMessage.id)} className="material-symbols-outlined cursor-pointer" title="Take a Photo">
          photo_camera
        </span>
        <span onClick={likeChat} className="material-symbols-outlined" title="Like">thumb_up</span>
        <span onClick={dislikeChat} className="material-symbols-outlined" title="Dislike">thumb_down</span>

      </div>
      <div className="flex place-content-center">
      {
        chatMessage.images.length>0 && <ChatImages images={chatMessage.images}/>
      }
      </div>
      <span className={'text-sm text-gray-500 right-0 absolute bottom-0'}>{createdAt.toLocaleString()}</span>
    </div>
  );
}

export default AIChatEntry;
