import React from "react";

function ProgressButton({ onNext = null, onPrevious = null, onComplete = null }) {
    return (
      <div className="flex space-x-4 p-3">
        {onPrevious && (
          <button onClick={onPrevious} className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded focus:outline-none">
            Previous
          </button>
        )}
        {onNext && (
          <button onClick={onNext} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded focus:outline-none">
            Next
          </button>
        )}
        {onComplete && (
          <button onClick={onComplete} className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded focus:outline-none">
            Submit
          </button>
        )}
      </div>
    );
  }

export default ProgressButton;
