import React from "react";
import logo from "../resources/logo_background.png"
import { useAuth } from "react-oidc-context";
function LoginPage() {
    const auth = useAuth();
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="text-center">
                <img className="w-20 mx-auto mb-4" alt="Kami Nexus Logo" src={logo}></img>
                <h2 className="text-2xl font-semibold mb-4">Welcome to the Kami Nexus Alpha!</h2>
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => void auth.signinRedirect()}
                >
                    Log in
                </button>
            </div>
        </div>
    );
}


export default LoginPage
