import { S3Client } from "@aws-sdk/client-s3";
import {fromCognitoIdentityPool} from "@aws-sdk/credential-providers"
import React, {useState, useEffect} from "react";
import { useAuth } from "react-oidc-context";


export const S3ClientContext = React.createContext(null);

function S3ClientComponent({children}){
    const region = process.env.REACT_APP_AWS_REGION?process.env.REACT_APP_AWS_REGION:"us-east-1"
    const idp_id = process.env.REACT_APP_AWS_IDENTITY_POOL_ID?process.env.REACT_APP_AWS_IDENTITY_POOL_ID: 'us-east-1:f6d0784c-ecad-4d83-badc-79d1030c11fc'
    const up_id = process.env.REACT_APP_AWS_USER_POOL_URL?process.env.REACT_APP_AWS_USER_POOL_URL: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_IX1LZh2jH'
    const [s3Client, setS3Client] = useState(null)
    const auth = useAuth();

    useEffect(()=>{
        if(auth.isAuthenticated){
            const creds = fromCognitoIdentityPool({
                clientConfig:{region:region},
                customRoleArn:"arn:aws:iam::657397319191:role/cognito_authenticated",
                identityPoolId:idp_id,
                logins:{
                  [up_id]:auth.user.id_token
                }
            })
            const s3 = new S3Client({
                region:region,
                credentials: creds})
            setS3Client(s3)
        }


    },[auth,idp_id,region,up_id]
    );



    return (
        <S3ClientContext.Provider value={s3Client}>
                {children}
        </S3ClientContext.Provider>
    )

}

export default S3ClientComponent;
