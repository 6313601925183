import React from "react";

import AuthenticatedImage from "./AuthenticatedImage";
function FeedItem({profilePic,postId,aiId,content,attachment, post_date, onLikeClick=null,onDislikeClick=null, onCommentClick=null}){

    return (
  <div className="flex flex-col items-center p-4 border border-gray-300 mb-4 rounded-lg max-w-2xl bg-gray-100">
    <div className="relative mb-2">
      <p className="text-gray-500">posted: {post_date}</p>
    </div>
    <div className="flex items-center">

      <div className="flex flex-row">
      <AuthenticatedImage imageUrl={profilePic} imgClass="w-10 h-10 rounded-full mr-4" />
        <p className="mb-2">{content}</p>
        <div className="flex items-center">
          <div className="flex flex-col mr-6">
            <span onClick={onLikeClick} className="material-symbols-outlined cursor-pointer" title="Like">
              thumb_up
            </span>
            <span onClick={onDislikeClick} className="material-symbols-outlined cursor-pointer" title="Dislike">
              thumb_down
            </span>
            <span onClick={onCommentClick} className="material-symbols-outlined cursor-pointer" title="Comment">
            comment
          </span>
          </div>

        </div>
      </div>
    </div>
  </div>
);

};


export default FeedItem;
