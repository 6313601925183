import React, {useState, useEffect} from "react";
import { useAuth } from "react-oidc-context";
import GalleryPopup from "../components/gallery/GalleryPopup";
import AuthenticatedImage from "../components/AuthenticatedImage";

function ImageGallery(){
    const [gallery, setGallery] = useState([])
    const [currentImage, setCurrentImage] = useState(null)
    const auth = useAuth();
    useEffect(()=>{
        const getGallery = async ()=>{
        if(auth.isAuthenticated){
            try{
            const token = auth.user?.access_token
            const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE}/images`,{
                method: "GET",
                headers:{
                    Authorization: `Bearer ${token}`,
                }
            });
            if (response.status === 200){
                setGallery(await response.json());
            }else{
                setGallery([])
            }

        } catch (e){
            console.error(e);
            setGallery([])
        }
    }
    };
    getGallery();
    },[auth.isAuthenticated, auth.user?.access_token]);
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {gallery.map((val, i) => (
            <div key={val.image_url}>
              <AuthenticatedImage
                imageUrl={val.image_url}
                onClick={() => {
                  setCurrentImage(i);
                }}
                imgClass="rounded-lg cursor-pointer"
              />
            </div>
          ))}
          {currentImage != null && currentImage >= 0 && (
            <GalleryPopup
              onClose={() => {
                setCurrentImage(null);
              }}
              image_url={
                currentImage >= 0 ? gallery[currentImage]?.image_url : null
              }
            />
          )}
        </div>
      );
}

export default ImageGallery;
