import React, {useState} from "react";
import ProgressButton from "./ProgressButtons";
import ImageGridSelector from "./ImageGridSelector";
function HairSelection({onNext=null,
    onPrevious=null,
    onComplete=null,
    hairStyleOptions=[{value:"Straight",image_src:""},{value:"Braids",image_src:""},{value:"Bangs",image_src:""},{value:"Curly ",image_src:""},{value:"bun",image_src:""},{value:"short",image_src:""}, {value:"long",image_src:""}, {value:"ponytail",image_src:""},{value:"pigtails",image_src:""},{value:"pixie cut",image_src:""}],
    hairColorOptions= [{value:"blonde",image_src:""},{value:"brunette",image_src:""},{value:"black ",image_src:""},{value:"redhead",image_src:""},{value:"pink",image_src:""},{value:"white",image_src:""},{value:"blue",image_src:""},{value:"green",image_src:""},{value:"purple",image_src:""}, {value:"multicolor",image_src:""}],
    lookup={}


    }){
    const [hairStyleSelection,setHairStyleSelection] = useState(lookup.hair_style)
    const [hairColorSelection,setHairColorSelection] = useState(lookup.hair_color)
    const [progressAttempt, setProgressAttempt] = useState(false)

    const isDataGood = ()=>{
        return hairStyleSelection&&hairColorSelection;

    }

    const handleNext = onNext?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onNext({hair_style:hairStyleSelection,hair_color:hairColorSelection})
        }}:null



    const handlePrevious =  onPrevious?()=>{

        onPrevious({hair_style:hairStyleSelection,hair_color:hairColorSelection})



    }:null
    const handleDone = onComplete?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onComplete({hair_style:hairStyleSelection,hair_color:hairColorSelection})
        }

    }:null
    return(
        <div>
            <ImageGridSelector onChange={(e)=>setHairColorSelection(
                                e.currentTarget.value
                                )}
                                selectorName="Hair Color"
                                flagHelp={!hairColorSelection&&progressAttempt}
                                attributeOptions={hairColorOptions}
                                value={hairColorSelection}
                                />
            <ImageGridSelector onChange={(e)=>setHairStyleSelection(
                                e.currentTarget.value
                                )}
                                selectorName="Hair Style"
                                flagHelp={!hairStyleSelection&&progressAttempt}
                                attributeOptions={hairStyleOptions}
                                value={hairStyleSelection}
                                />


        <ProgressButton onPrevious={handlePrevious} onNext={handleNext} onComplete={handleDone}/>
        </div>
    )

}
export default HairSelection;
