import React from "react";
import colors from "../../styles/Colors.module.css"

function ProgressBar({progress=0}){
    return(
    <div className={`${colors.lightGray}`}>
        <div className={`${colors.blue} ${colors.round} ${colors.container}`} style={{width:`${progress}%`}}>{progress}%</div>
    </div>
    )
}

export default ProgressBar;
