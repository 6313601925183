import * as React from "react";
import * as ReactDOM from "react-dom/client";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "react-oidc-context";

const root = ReactDOM.createRoot(document.getElementById('root'));
const oidcConfig = {
  authority: process.env.REACT_APP_KEYCLOAK_AUTHORITY,
  client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI?process.env.REACT_APP_REDIRECT_URI:"http://localhost:3000",

  scope: "openid aws.cognito.signin.user.admin profile email com.kaminexus/admin:user com.kaminexus/user:chat:create com.kaminexus/user:chat:read com.kaminexus/user:job:create com.kaminexus/user:job:read com.kaminexus/user:profiles:create com.kaminexus/user:profiles:read com.kaminexus/user:image:create com.kaminexus/user:image:read com.kaminexus/user:info:read com.kaminexus/user:info:update",


  onSigninCallback: (_user) => {
         window.history.replaceState(
             {},
             document.title,
             window.location.pathname
         )
     },
  automaticSilentRenew: true


};

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
    <App />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
