import React, {useState, useEffect, useCallback}  from "react";
import ChatHistory from "./ChatHistory";
import ChatInput from "./ChatInput";
import ChatTypingAnimation from "./ChatTypingAnimation";
import { useAuth } from "react-oidc-context";

function IndividualChatWindow({ currentUser="frontendTestUser", profileID=null, senderName="Friend", senderType="USER", className}){
    const [currentChatHistory,setChatHistory] = useState([]);
    const [currentChatMessage, setChatMessage] = useState("");
    const [currentChatJob, setCurrentChatJob] = useState(null);
    const [currentProfile, setCurrentProfile] = useState(null);
    const [sendingMessage, setSending] = useState(false);
    const auth = useAuth();

    const sendMessage = useCallback(async ( )=>{


        if(auth.isAuthenticated){
            setSending(true)
            try{
            const token = auth.user?.access_token
            const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE}/chat`,{
                method: "PUT",
                headers:{
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    content:currentChatMessage,
                    sender_name:senderName,
                    sender_type:senderType,
                    profile_id: profileID
                })
            });
            // setChatHistory(await response.json()); TODO logic for checking job status
            setChatMessage("")
            let r= await response.json()
            setChatHistory(r.chat_history)
            setCurrentChatJob(r.current_job)

        } catch (e){
            console.error(e);
        } finally{
            setSending(false)
        }



    }else{
            alert(currentChatMessage)
        }
    },[auth,currentChatMessage, senderName, senderType,profileID]
    )
    const getProfile = useCallback(async()=>{
        if(auth.isAuthenticated && profileID){
            try{
            const token = auth.user?.access_token
            const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE}/profiles/${profileID}`,{
                method: "GET",
                headers:{
                    Authorization: `Bearer ${token}`,
                }
            });
            if(response.status === 200){
                setCurrentProfile(await response.json());
            }

        } catch (e){
            console.error(e);
        }
    }
    },[profileID,auth])

    let getChatHistory = useCallback(async ()=>{
        if(auth.isAuthenticated && profileID){
            try{
            const token = auth.user?.access_token
            const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE}/chat/${profileID}/history`,{
                method: "GET",
                headers:{
                    Authorization: `Bearer ${token}`,
                }
            });
            if(response.status === 200){
                setChatHistory(await response.json());
            }

        } catch (e){
            console.error(e);
        }
    }
    },[profileID,auth]
    )

    const getJobStatus = useCallback(async()=>{
        await auth.clearStaleState()
        if(auth.isAuthenticated && profileID){
            try{
            const token = auth.user?.access_token
            const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE}/profiles/${profileID}/chat/job_status`,{
                method: "GET",
                headers:{
                    Authorization: `Bearer ${token}`,
                }
            });
            if(response.status === 200){
                let job_status_response = await response.json()
                setCurrentChatJob(job_status_response);
            }

        } catch (e){
            console.error(e);
        }


    }

    },[auth,profileID])

    useEffect(()=>{
    getChatHistory();
    const interval = setInterval( async ()=>{
        await getChatHistory()
    }, 10000
    );
    return () => clearInterval(interval);
    },[auth, profileID, getChatHistory]);


    useEffect(()=>{

            getJobStatus();

            const interval = setInterval( ()=>{

                getJobStatus()
            }, 10000
            );
            return () => clearInterval(interval)
        },[auth, profileID, getJobStatus]
    )
    const getTypingAnimation = (currentChatJob)=>{
        if(currentChatJob != null){
            if(currentChatJob.job_status==="IN_PROGRESS"){
                return <ChatTypingAnimation profile_name={currentProfile?.name}/>
            }
        }

    }
    useEffect(
        ()=>{
            getProfile();
        },[auth.isAuthenticated,profileID, getProfile]
    )

    const profile_image = (profile) =>{
        if(!profile){
            return null
        }
        if(profile.profile_images.length>0){
            return profile.profile_images[0].image_url
        }

    }
    //useEffect to regularly check for chat history
    return profileID ? (
        <div className={className}>
          <h2 className="text-xl font-bold">Chat with {currentProfile?.name}</h2>
            <ChatHistory chatHistory={currentChatHistory} currentUser={currentUser} ai_image={profile_image(currentProfile)} profileID={profileID} />
          {getTypingAnimation(currentChatJob)}
          <ChatInput onSend={sendMessage} onChange={(val) => setChatMessage(val.currentTarget.value)} textContent={currentChatMessage} sending={sendingMessage} />
        </div>
      ) : (
        <div></div>
      );

}

export default IndividualChatWindow;
