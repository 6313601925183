import React from "react";
import styles from "../../styles/ImageGallery.module.css";
import AuthenticatedImage from "../AuthenticatedImage";

function GalleryPopup({image_url=null, onClose=null}){

    return(
        image_url?(<div className={styles.ImageGalleryPopupContainer}>

            <AuthenticatedImage imageUrl={image_url} imgClass={styles.ImageGalleryPopupImage}/>

            <div onClick={onClose} className={styles.ExitButton}>X</div>

        </div>):null
    )

}

export default GalleryPopup
