import React, {useState} from "react";
import ProgressButton from "./ProgressButtons";
import ImageGridSelector from "./ImageGridSelector";
import MultiSelectGrid from "./MultiSelectGrid";

function OccupationHobbySelector({onNext=null,
    onPrevious=null,
    onComplete=null,
    occupationOptions=[{value:"waiter"},{value:"paramedic"},{value:"dentist"},{value:"student"},{value:"nurse"},{value:"electrician"},{value:"doctor"},{value:"gloomy"},{value:"surgeon"},{value:"secretary"},{value:"soldier"},{value:"scientist"},{value:"reporter"},{value:"construction worker"},{value:"professor"},{value:"police officer"},{value:"postal worker"},{value:"lifeguard"},{value:"housekeeper"},{value:"gardner"}, {value:"pool cleaner"}, {value:"software developer"}, {value:"aerospace engineer"},{value:"farmer"}, {value:"forest ranger"}],
    hobbyOptions=[{value:"video games"},{value:"painting"},{value:"gardening"},{value:"reading"},{value:"dancing"}, {value:"collecting antiques"}, {value:"watching tv"}, {value:"pottery"}, {value:"playing cards"},{value:"board games"},{value:"yoga"}, {value:"scrap booking"}, {value:"photography"}, {value:"writing"}, {value:"volunteering"}, {value:"fishing"}, {value:"brewing"}, {value:"knitting"}, {value:"cosplay"}, {value:"travel"}, {value:"shopping"}, {value:"scuba diving"}, {value:"jewelry making"},{value:"origami"}, {value:"baking"}, ],
    maxHobbies=3,
    minHobbies=1,
    lookup={}


    }){
    const [occupationSelection,setOccupationSelection] = useState(lookup.occupation)
    const [hobbySelection,setHobbySelection] = useState(lookup.hobbies?lookup.hobbies:[])
    const [progressAttempt, setProgressAttempt] = useState(false)

    const isDataGood = ()=>{

        return occupationSelection&&(hobbySelection.length>=minHobbies&&hobbySelection.length<=maxHobbies);

    }

    const handleNext = onNext?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onNext({hobbies:hobbySelection,occupation:occupationSelection})
        }}:null



    const handlePrevious =  onPrevious?()=>{

        onPrevious({hobbies:hobbySelection,occupation:occupationSelection})



    }:null
    const handleDone = onComplete?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onComplete({hobbies:hobbySelection,occupation:occupationSelection})
        }

    }:null
    return(
        <div>
            <ImageGridSelector onChange={(e)=>setOccupationSelection(
                                e.currentTarget.value
                                )}
                                selectorName="Occupation"
                                flagHelp={!occupationSelection&&progressAttempt}
                                attributeOptions={occupationOptions}
                                value={occupationSelection}
                                />
            <MultiSelectGrid onChange={(e)=>{
                if(e.target.checked){
                    // if the target is checked, extend to existing list

                    setHobbySelection(
                        [...hobbySelection,e.target.value]
                    )
                }else{
                    setHobbySelection(

                        hobbySelection.filter((val)=>{
                            return val!==e.target.value
                        })
                    )

                }
            }} selectorName="Hobbies" flagHelp={!(hobbySelection.length>minHobbies&&hobbySelection.length<maxHobbies)&&progressAttempt} attributeOptions={hobbyOptions} values={hobbySelection} maxSelect={maxHobbies} minSelect={minHobbies}/>


        <ProgressButton onPrevious={handlePrevious} onNext={handleNext} onComplete={handleDone}/>
        </div>
    )

}
export default OccupationHobbySelector;
