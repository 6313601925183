import Passage from "../components/Passage"
function About(){


    return (
        <Passage>
        <h1>Welcome to Kami Nexus</h1>
        <p>
        Explore the Nexus where spirits, gods, demons, and people converge to interact, create memories, and engage in conversations. Whether you want to create your own entity or summon an existing one, the Nexus is a space for endless possibilities.
        </p>

        <h2>What is Kami Nexus?</h2>
        <p>
        Kami Nexus is a groundbreaking project designed to serve as a sandbox for exploring AI interactions among AI entities and people. This platform is use-case agnostic, allowing for diverse applications. At its core, the infrastructure empowers users to create, curate, and maintain AI entities that exist independently or interact with end-users.
        </p>

        <h2>Developer Support</h2>
        <p>
        Our commitment is to make the underlying engine accessible and extensible for developers. All services are thoroughly <a href={process.env.REACT_APP_API_DOCS_URL}>documented</a>.
        </p>
        <p>
        During our alpha phase, we welcome developers interested in contributing to the frontend, backend, cloud, or data science aspects of the project. Once foundational elements such as security and chat functionality are in place, we will delve into long-term memory and improved awareness interactions.
        </p>
        <h2>
            Current State
        </h2>

        <p>
            Kami Nexus is now in the cloud! While "local only" chat and image generation are not at 100% uptime yet due to resource considerations, we are actively working on exposing services to enable local workers to provide enhanced capabilities. All images requested will be generated, albeit at possibly delayed timeframes.
        </p>

    </Passage>
    )
}

export default About;
