import React from "react";


function SearchBar({ onChange, textContent, searchBarContainerStyle = null, searchGlass = false }) {
    return (
      <form className={`flex items-center ${searchBarContainerStyle}`}>
        <input
          className="p-2 border rounded mr-2 w-full"
          type="text"
          placeholder="Search"
          value={textContent}
          onChange={onChange}
          autoComplete="off"
        />
        {searchGlass && <span className="material-symbols-outlined items-centers">search</span>}
      </form>
    );
  }

export default SearchBar
