import React from "react";

function ChatInput({onSend, onChange, textContent, sending=false}){
    function handleKeyPress(event) {
        if (event.key === 'Enter' && !sending) {
            onSend()
        }
      }

      return (
        <form className={`flex items-center`}>
          <input
            id="ChatInput input-field"
            className="flex-1 p-2 border rounded mr-2"
            type="text"
            placeholder="Type your message here"
            value={textContent}
            onChange={onChange}
            onKeyPress={handleKeyPress}
            autoComplete="off"
          />
          <button
            id="ChatInput submit-button"
            className="bg-blue-500 text-white px-4 py-2 rounded cursor-pointer"
            onClick={onSend}
            disabled={sending}
          >
            Send
          </button>
        </form>
      );
}

export default ChatInput
