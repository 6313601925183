import './App.css';
import Layout from './pages/Layout';
import FeedView from './pages/FeedView';
import About from './pages/About';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from './pages/NoPage';
import ProfileView from './pages/ProfileView'
import ImageGallery from './pages/ImageGallery';
import SettingsPage from './pages/SettingsPage';
import AICreationScreen from './pages/AICreationScreen';
import ChatView from './pages/ChatView';
import { CookiesProvider } from 'react-cookie';
import { useAuth } from 'react-oidc-context';
import LoginPage from './pages/LoginPage';
import S3ClientComponent from './components/S3ClientComponent';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import PaymentPage from './pages/PaymentPage';

function App() {
  const auth = useAuth();

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
        default:

    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    if (auth.isAuthenticated) {

      return (
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
          <S3ClientComponent>
        <BrowserRouter>
        <Routes>
          <Route exact="/" element={<Layout />}>
            <Route index element={<About />} />
            <Route path="about" element={<About />} />
            <Route path="feed" element={<FeedView />} />
            <Route path="profiles" element={<ProfileView />} />
            <Route path="gallery" element={<ImageGallery />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="createai" element={<AICreationScreen/>} />
            <Route path="chat" element={<ChatView/>} >

              <Route path=":profileID" element={<ChatView/>} />
            </Route>
            <Route path="tos" element={<TermsAndConditions/>}/>
            <Route path="privacy" element={<PrivacyPolicyPage/>}/>
            <Route path="payment" element={<PaymentPage/>}/>
            <Route path="*" element={<NoPage/>} />


          </Route>
        </Routes>
      </BrowserRouter>
      </S3ClientComponent>
      </CookiesProvider>
      );
    }
    return <LoginPage/>;
  }


export default App;
