import React, {useState, useEffect} from "react";
import FeedItem from "../components/FeedItem";
import { useAuth } from "react-oidc-context";
import styles from "../styles/Feed.module.css";
function FeedView({limit=10}){
    const [posts, setPosts] = useState({});
    const [profiles, setProfiles] = useState({})
    const auth = useAuth();
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const update_lookup = (data)=>{
        let tmp = {};
        for (let val of data){
            tmp[val.id] = val.profile_images.length>0?val.profile_images[0].image_url:null
        }
        setProfiles(tmp);
    }
    const fetchFeed = async ()=>{
        setIsLoading(true);
        setError(null);
        if(auth.isAuthenticated){
            try{
            const token = auth.user?.access_token
            const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE}/feed?publish_time_lte=${Date.now()}&sort_by=publish_time&sort_desc=true&offset=${page*limit}&limit=${limit}`,{
                method: "GET",
                headers:{
                    Authorization: `Bearer ${token}`,
                },
                params:{

                }
            });
            if (response.status === 200){
                let data = await response.json();
                if(data.length > 0){
                setPosts({...posts,...{[page]:data}})
                setPage(prevPage => prevPage + 1);
                }

            }else{
                setPosts([])
            }

        } catch (e){
            console.error(e);
            setError(error);
        }finally{
            setIsLoading(false);
        }
        return () =>{
            setPosts([])
        }
    }
    };




    useEffect(()=>{

        fetchFeed();
        },[]);
    useEffect(()=>{
        const getProfiles = async ()=>{
        if(auth.isAuthenticated){
            try{
            const token = auth.user?.access_token
            const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE}/profiles`,{
                method: "GET",
                headers:{
                    Authorization: `Bearer ${token}`,
                }
            });
            if(response.status === 200){
                update_lookup(await response.json());

            }else{
                setProfiles([])
            }


        } catch (e){
            console.error(e);
            setProfiles([])
        }
    }
    };
getProfiles();
},[auth]);


const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isLoading) {
      return;
    }
    fetchFeed();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading]);

  const getListFromPages = (input)=>{
    let out= []
    for(let key in input){
        out = [...out,...input[key]]
    }
    return out

  }

  return (
    <div className={styles.FeedContainer}>
    <ul>
    {getListFromPages(posts).map( (post) => { return (
        <li key={post.id}>
            <FeedItem aiId={post.profile_id} content={post.content} profilePic={profiles[post.profile_id]} attachment={post.attachment} post_date={post.publish_time}/>
        </li>
        )
    })}
    </ul>
    </div>

)
}

export default FeedView;
