import React, {useState} from "react";
import ProgressButton from "./ProgressButtons";
import ImageGridSelector from "./ImageGridSelector";
import RangeSelector from "./RangeSelector";
import amber from "../../resources/eyes/amber.jpg"
import blue from "../../resources/eyes/blue.jpg"
import brown from "../../resources/eyes/brown.jpg"
import gray from "../../resources/eyes/gray.jpg"
import green from "../../resources/eyes/green.jpg"
import hazel from "../../resources/eyes/hazel.jpg"

function PhysicalAppearanceSelection({onNext=null,
    onPrevious=null,
    onComplete=null,
    ethnicityOptions=[{value:"caucasian",image_src:""},{value:"latina",image_src:""},{value:"asian ",image_src:""},{value:"arab ",image_src:""},{value:"black/afro ",image_src:""}],
    ageMin=18,
    ageMax=120,
    eyeOptions= [{value:"brown",image_src:brown},{value:"blue",image_src:blue},{value:"hazel",image_src:hazel},{value:"amber",image_src:amber},{value:"gray",image_src:gray},{value:"green",image_src:green}],
    lookup={}


    }){
    const [ethnicitySelection,setEthnicitySelection] = useState(lookup.ethnicity)
    const [ageSelection,setAgeSelection] = useState(lookup.age?lookup.age:Math.round(ageMin+(ageMax-ageMin)/2))
    const [eyeSelection,setEyeSelection] = useState(lookup.eyes)
    const [progressAttempt, setProgressAttempt] = useState(false)

    const isDataGood = ()=>{
        return ageSelection&&ethnicitySelection&&eyeSelection;

    }

    const handleNext = onNext?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onNext({ethnicity:ethnicitySelection,age:ageSelection,eyes:eyeSelection})
        }}:null



    const handlePrevious =  onPrevious?()=>{

        onPrevious({ethnicity:ethnicitySelection,age:ageSelection,eyes:eyeSelection})



    }:null
    const handleDone = onComplete?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onComplete({ethnicity:ethnicitySelection,age:ageSelection,eyes:eyeSelection})
        }

    }:null
    return(
        <div>
            <ImageGridSelector onChange={(e)=>setEthnicitySelection(
                                e.currentTarget.value
                                )}
                                selectorName="Ethnicity"
                                flagHelp={!ethnicitySelection&&progressAttempt}
                                attributeOptions={ethnicityOptions}
                                value={ethnicitySelection}
                                />
            <RangeSelector onChange={(e)=>{setAgeSelection(e)}}
                name = "Age"
                minVal = {ageMin}
                maxVal = {ageMax}
                value = {ageSelection}
                flagHelp={!ethnicitySelection&&progressAttempt}  />
            <ImageGridSelector onChange={(e)=>setEyeSelection(
                                e.currentTarget.value
                                )}
                                selectorName="Eye"
                                flagHelp={!eyeSelection&&progressAttempt}
                                attributeOptions={eyeOptions}
                                value={eyeSelection}
                                />


        <ProgressButton onPrevious={handlePrevious} onNext={handleNext} onComplete={handleDone}/>
        </div>
    )

}
export default PhysicalAppearanceSelection;
