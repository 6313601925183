import React from "react";
function FooterComponent() {
  return (
    <div className="bg-gray-800 text-white font-xs md:font-medium py-4">
      <nav className="container mx-auto flex justify-between items-center flex-col md:flex-row">
        <span>KamiNexus.com @ 2023. All rights Reserved.</span>
        <div className="flex space-x-4">
          <a href="tos" className="hover:text-gray-400">
            Terms and Conditions
          </a>
          <a href="privacy" className="hover:text-gray-400">
            Privacy Policy
          </a>
        </div>
      </nav>
    </div>
  );
}


export default FooterComponent;
