import React, {useState} from "react";
import ProgressButton from "./ProgressButtons";
import MultiSelectGrid from "./MultiSelectGrid";

function FavoriteClothingSelector({onNext=null,
    onPrevious=null,
    onComplete=null,
    clothingOptions=[{value:"summer dress"},{value:"latex"},{value:"school"},{value:"swimwear"},{value:"yoga"},{value:"leather"},{value:"armor"},{value:"maid"},{value:"sleepwear"},{value:"office"},{value:"goth"},{value:"punk"},{value:"relaxed"},{value:"disheveled"},{value:"cosplay"},{value:"military"},{value:"tribal"}],
    maxClothing=3,
    minClothing=1,
    lookup={}



    }){
    const [clothingSelection ,setClothingSelection] = useState(lookup.clothing?lookup.clothing:[])
    const [progressAttempt, setProgressAttempt] = useState(false)

    const isDataGood = ()=>{
        return clothingSelection.length>=minClothing&&clothingSelection.length<=maxClothing;

    }

    const handleNext = onNext?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onNext({clothing:clothingSelection})
        }}:null



    const handlePrevious =  onPrevious?()=>{

        onPrevious({clothing:clothingSelection})



    }:null
    const handleDone = onComplete?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onComplete({clothing:clothingSelection})
        }

    }:null
    return(
        <div>

            <MultiSelectGrid onChange={(e)=>{
                setClothingSelection(
                    (prevSelection)=>{
                        if(e.target.checked){
                            return [...prevSelection,e.target.value]
                        }else{
                            return prevSelection.filter((val)=>{return val!==e.target.value})
                        }
                    }
                )



            }} selectorName="Clothing Preferences" flagHelp={!isDataGood()&&progressAttempt} attributeOptions={clothingOptions} values={clothingSelection} maxSelect={maxClothing} minSelect={minClothing}/>


        <ProgressButton onPrevious={handlePrevious} onNext={handleNext} onComplete={handleDone}/>
        </div>
    )

}
export default FavoriteClothingSelector;
