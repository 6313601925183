import React from "react";

function UserChatEntry({
  chatMessage
}) {
  const createdAt = new Date(chatMessage.created_on);

  return (
    <div className='border bg-gray-300 border-gray-300 rounded p-4 mb-4 relative flex min-h-50 w-11/12 float-right' key={chatMessage.id}>
      <div className="flex">
        {/* <img className={`w-12 h-12 rounded-full float-left`} src={image_placeholder} alt="Avatar" /> */}
        <p className={'text-right'}>{chatMessage.content}</p>
      </div>

      <span className={'text-sm text-gray-600 right-0 absolute bottom-0'}>{createdAt.toLocaleString()}</span>
    </div>
  );
}

export default UserChatEntry;
