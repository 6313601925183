import React from "react";

function MultiSelectGrid({ onChange, selectorName, values = [], flagHelp = false, attributeOptions = [], minSelect = 1, maxSelect = 5,description=null }) {
  return (
    <div>
      <h2 className="text-lg font-semibold mb-2">{selectorName} (Select between {minSelect} and {maxSelect})</h2>
      {description && <p className="text-gray-600 mb-4">{description}</p>}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {attributeOptions.map((item) => (
          <label
            key={item.value}
            className={`relative flex place-content-center p-4 border rounded cursor-pointer transition duration-300 transform hover:scale-105 ${
              values.includes(item.value) ? 'bg-blue-200 border-blue-500' : 'bg-gray-100 border-gray-300'
            }`}
          >
            <input
              type="checkbox"
              className="absolute top-0 left-0 opacity-0 cursor-pointer"
              name={item.value}
              value={item.value}
              onChange={onChange}
              checked={values.includes(item.value)}
            />
            <div className="flex flex-col items-center">
              {item.image_src && <img className="w-16 h-16 object-cover rounded-full mb-2" src={item.image_src} alt={item.value} />}
              <div className="text-center font-medium">{item.value}</div>
              {item.description && <div className="text-sm text-gray-500">{item.description}</div>}
            </div>
          </label>
        ))}
      </div>
      {flagHelp && values.length > maxSelect && <p className="mt-2 text-red-500">Too many items! Please select no more than {maxSelect}</p>}
      {flagHelp && values.length < minSelect && <p className="mt-2 text-red-500">Not enough items! Please select at least {minSelect}</p>}
    </div>
  );
}

export default MultiSelectGrid;
