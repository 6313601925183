import React, {useState} from "react";
import styles from "../styles/ProfileCard.module.css"
import create_image from '../resources/create_character_image.png'
import AuthenticatedImage from "./AuthenticatedImage";
function ProfileCard({name, bio, attributes={},image, onClick=null, profileID,onDeleteClick=null, onCloneClick=null, onPhotoClick=null, onVitalsClick=null,image_requires_auth=false}){
    const keys = Object.keys(attributes)

    const [flipped,setFlipped] = useState(false)

    const onClickHanlder = onClick?onClick:()=>{setFlipped(!flipped)}

    return (
        <div className={`${styles.card} ${flipped?styles.flip:""}`}>
            <div className={styles.front} onClick={onClickHanlder}>
                {image_requires_auth&&image?<AuthenticatedImage imageUrl={image} backupImage={create_image}imgClass={styles.ProfileCardImage}/>:  <img className={styles.ProfileCardImage} src={create_image} alt="Missing"/>}
                <div className={styles.ProfileCardBio}>
                <h2 className={styles.ProfileCardName}>{name}</h2>
                <p>{bio}</p>
                </div>

            </div>
            <div className={styles.back} onClick={onClickHanlder}>
            { keys.length>0 &&
                <div>
                    <h3>Attributes</h3>


                <ul>
                    {
                        keys.map(
                            (key,i) =>{
                                return <li key={key}><b>{key}:</b>{attributes[key]}</li>
                            }
                        )
                    }
                </ul>

                </div>
                }

            </div>
            {(!flipped && profileID)&& <div className={styles.ProfileCardActionContainer}>


                <span onClick={onDeleteClick} class="material-symbols-outlined" title="Delete">delete_forever</span>
                <span onClick={onCloneClick} class="material-symbols-outlined" title="Clone">content_copy</span>
                <a href={`chat/${profileID}`}><span class="material-symbols-outlined" title="Chat">chat</span></a>
                <span onClick={onPhotoClick} class="material-symbols-outlined" title="Take a Photo">photo_camera</span>
                <span onClick={onVitalsClick} class="material-symbols-outlined" title="Vitals">monitor_heart</span>

            </div>}
        </div>

    )
};

export default ProfileCard;
