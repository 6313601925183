import React from "react";
import Passage from "../components/Passage";
function PrivacyPolicyPage({website="kaminexus.com"}){

    return (
        <Passage>
            <h1>DRAFT Privacy Policy</h1>
            <p>Pursuant to our Terms of Use (the “Terms”), this document describes how we treat personal information related to your use of the {website} website (the “Website”) and the “Services” (as defined in the Terms) including information you provide when using it.</p>
            <p>We expressly and strictly limit use of the Website to adults over 18 years of age or the age of majority in the individual’s jurisdiction, whichever is greater. Anyone under this age is strictly forbidden from using the Website. We do not knowingly seek or collect any personal information or data from persons who have not attained this age.</p>
            <h2>Data Collected</h2>
            <p>Browsing the Website. If you access the Website without having an account, your IP address, country of origin and other information about your computer or device (such as web requests, device type, browser type, browser language, referring URL, language settings, your mobile carrier, the links you click, the pages you, view, any data you voluntarily provide, session length, cookie information operating system and date and time of requests) may be recorded for log file information, aggregated traffic information and in the event that there is any misappropriation of information and/or content. If you have an account on the Website and are logged in, the information may be associated with your account. ¸</p>
            <p>Registering on the Website. Registration of an account is required for using many parts of the Website, including purchasing subscriptions and/or access to certain content and using many other features. We may collect any of the pieces of information listed above and request additional information at the time of your registration, including, without limitation, your name, email address, password, address, and date of birth. Your IP address is recorded automatically. We may ask you to provide payment information such as credit cards, debit cards, or bank account information, we do not retain this information, but rather this information is held by our third-party payment processors and their affiliates and are subject to their respective privacy policies. We recommend that you review such privacy policies before using and of their services. We may ask for further information to fill out your profile details. You may also voluntarily provide other data and information when using the Services. Some of this information may be used to provide you with the Services.</p>
            <p>Usage Information. We may record information about your usage of the Website such as pageviews, clicks, mouse moves, duration of video views, portions of videos viewed, search phrases, messages sent or received, referrers, and other various statistics. If you have registered and are logged in, we may associate that information with your account.</p>
            <p>Uploaded Content. Any personal information, data, or content you voluntarily provide in relation to using the Services will be collected by us and may be used by us to develop and improve the Services.</p>
            <p>Correspondences. We may keep a record of any correspondence between you and us.</p>
            <p>Cookies. When you use the Website, we may send cookies to your computer to uniquely identify your browser session. We may use both session cookies and persistent cookies.</p>
            <p>Payment Information. If you purchase anything from us, you must provide payment information, including credit card, debit cards or bank account information directly to our third-party payment processors and their affiliates. We do not ourselves keep your payment information in connection with your purchases. All transactions processed by our third-party payment processors and their affiliates are subject to their respective privacy policies. We recommend that you review such privacy policies before using and of their services.</p>
            <h2>Data Usage</h2>
            <p>We may use your information and data to provide you with certain features and to create a personalized experience on the Website. We may also use that information to operate, maintain and improve features and functionality of the Website and the Services.</p>
            <p>We may use your e-mail address or other personal information to send commercial or marketing messages. We may use your e-mail for non-marketing or administrative purposes.</p>
            <p>We use cookies, web beacons and other information to store information so that you will not have to re-enter it on future visits, provide personalized content and information, monitor the effectiveness of the Website and monitor aggregate metrics such as the number of visitors and page views (including for use in monitoring visitors from affiliates). They may also be used to provide targeted advertising based on your country of origin and other personal information.</p>
            <p>We may aggregate your personal information with personal information of other members and users and disclose such information to advertisers and other third-parties for marketing and promotional purposes.</p>
            <p>We may use your information to run promotions, contests, surveys and other features and events.</p>
            <p>We many use your information to verify your identity, deliver membership benefits, send you messages and notification, provide you with customer support, personalize your experience on the Website, understand and analyze how you use the Services, prevent fraud, abuse, and illegal activities with respect to the Website.</p>
            <h2>Disclosures of Information</h2>
            <p>We may be required to release certain data to comply with legal obligations or in order to enforce our Terms and other agreements. We may also release certain data to protect the rights, property or safety of us, our users and others. This includes providing information to other companies or organizations like the police or governmental authorities for the purposes of protection against or prosecution of any illegal activity, whether or not it is identified in the Terms.</p>
            <p>If you upload any illegal or unauthorized material to the Website, or you are suspected of doing such, we may forward all available information to relevant authorities, including respective copyright owners, without any notice to you.</p>
            <p>We may also share your information with third parties for the purposes of payment processing and any such information will also be subject to their privacy policies.</p>
            <h2>Miscellaneous</h2>
            <p>If you have an account on the Website and have a password giving you access to certain parts of the Website, you are responsible for keeping the password confidential. Anyone else with your password may access your account and other personal information.</p>
            <p>While we use commercially reasonable physical, managerial and technical safeguards to secure your information, the transmission of information via the internet is not completely secure and we cannot ensure or warrant the security of any information or content you transmit to us. Any information or content you transmit to us is done at your own risk.</p>
            <h2>Disclosure</h2>
            <p>The Website employs certain physical, managerial, and technical safeguards to preserve the integrity and security of the information collected. We cannot, however, ensure or warrant that the safeguards will be uninterrupted or effective, or that any information you transmit to the Website will remain secure. Therefore, any information you transmit is done at your own risk.</p>
            <p>Once we receive your transmission of information, Website employs certain techniques and technologies to protect the security of our systems and your information. However, please note that this is not a guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. We may collect and disclose personal information, including your photos and usage data, to governmental authorities or agencies, including law enforcement agencies, at their request or pursuant to a court order, subpoena or other legal process, if there is a good faith belief that such collection or disclosure is required by law.</p>
            <br></br>
            <p>BY USING THIS WEBSITE AND/OR SERVICES, YOU AGREE THAT YOU CONSENT TO THIS PRIVACY POLICY AND ANY CHANGES HERETO IN THE FUTURE. YOU AGREE THAT THE WEBSITE MAY MAKE CHANGES TO THIS PRIVACY POLICY AT ANY TIME WITHOUT ANY GIVEN NOTICE AT ITS DISCRETION</p>

            </Passage>
    )

}

export default PrivacyPolicyPage;
