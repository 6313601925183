function Passage({ children }) {

    return (
        <div className="max-w-screen-sm rounded border-2 p-5 shadow m-auto  text-neutral-600 [&_h1]:text-neutral-900  [&_h2]:text-neutral-900 [&_h2]:mt-4">
            {children}
        </div>
    );

};


export default Passage;
