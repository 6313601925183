import React,{useEffect, useState} from "react";

import { useAuth } from "react-oidc-context";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export default function PaymentPage() {
  const stripe_config = {
    pricingTableID:process.env.REACT_APP_STRIPE_PRICING_TABLE_ID,
    publicKey:process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  }
  const auth = useAuth();
  const [stripeID, setStripeID] = useState(null)
  useEffect(()=>{
    const getUserInfo = async ()=>{
        if(auth){
            const token = auth.user?.access_token
            const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE}/payment/stripe-id`,{
                method: "GET",
                headers:{
                    Authorization: `Bearer ${token}`,
                }
            });
            if(response.status === 200){
              setStripeID(await response.json());
            }else{
                setStripeID(null)
            }
        }
    }
    getUserInfo();
}, [auth])

  return (
    <div>
    { (auth.isAuthenticated && stripeID) && (<div><script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      <stripe-pricing-table
        pricing-table-id={stripe_config.pricingTableID}
        publishable-key={stripe_config.publicKey}
        customer-session-client-secret={stripeID}


      >
      </stripe-pricing-table>
      </div>
    )
    }
   </div>


  );
};
