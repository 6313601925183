import React from "react";
import AuthenticatedImage from "../AuthenticatedImage";

function ChatBarProfile({ profileImage, profileName, onClick , className}) {
    return (
      <div
        onClick={onClick}
        className={`${className} relative`}
      >
        <AuthenticatedImage
          imageUrl={profileImage}
          imgClass=" h-full border-0 rounded-l p-0 mr-2 object-contain"
        />
        <div class="absolute bottom-0 px-4 py-3 bg-gray-500/50 w-full">
        <h2 className="text-white font-semibold text-2xl overflow-hidden truncate">
          {profileName}
        </h2>
        </div>
      </div>
    );
  }

export default ChatBarProfile;
