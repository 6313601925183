import React, { useEffect } from "react";
import styles from "../../styles/ChatView.module.css"

import { useAuth } from "react-oidc-context";
import UserChatEntry from "./UserChatEntry";
import AIChatEntry from "./AIChatEntry";
function ChatHistory({currentUser,chatHistory=[], ai_image =null, profileID}){
    const auth = useAuth();

    const likeChat = async(chatID) => {

    }
    const dislikeChat = async(chatID)=>{

    }
    const alertChat = async(chatID)=>{

    }
    const takePicture = async(chatID)=>{

        if(window.confirm("Would you like to generate a picture from this message?")){
              // User clicked OK
              try{
                const token = auth.user?.access_token
                const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE}/chat/${profileID}/selfie/${chatID}`,{
                    method: "PUT",
                    headers:{
                        Authorization: `Bearer ${token}`,
                    }
                });
                if(response.status !== 201){
                    window.alert("Problem Submitting Photo Request! Try again soon")
                }
            } catch (e){
                console.error(e);

            }
            return;
          } else {

            return;
          }
    }


    chatHistory.sort((a,b)=>{
        let da = new Date(a.created_on),
            db = new Date(b.created_on);

        return  da - db;
    })
    // useEffect(()=>{
    //     document.querySelector("."+styles.ChatHistoryContainer).scrollTop = document.querySelector(".test").scrollHeight
    // },[chatHistory])

    return (
        <div className="overflow-y-scroll">
        {chatHistory.map((val)=> {
            if(val.sender_type==="USER"){
                return (<UserChatEntry chatMessage={val}/>)
            }else{
                return (
                    <AIChatEntry
                    chatMessage={val}
                    ai_image={ai_image}
                    alertChat={()=>{alertChat(val.id)}}
                    dislikeChat={()=>{dislikeChat(val.id)}}
                    likeChat={()=>{likeChat(val.id)}}
                    takePicture={()=>{takePicture(val.id)}}
                    />
                    )
            }
        }



        )}
        </div>
    )

}

export default ChatHistory;
