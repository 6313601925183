import React, {useState} from "react";
import ProgressButton from "./ProgressButtons";
import ImageGridSelector from "./ImageGridSelector";
function FacialHairSelection({onNext=null,
    onPrevious=null,
    onComplete=null,
    facialHairType=[{value:"soul patch",image_src:""},{value:"handle bar mustache",image_src:""},{value:"fu manchu",image_src:""},{value:"goatee",image_src:""},{value:"mutton chops",image_src:""},{value:"neckbeard",image_src:""}, {value:"beard",image_src:""}],
    facialHairColor= [{value:"blonde",image_src:""},{value:"brunette",image_src:""},{value:"black ",image_src:""},{value:"redhead",image_src:""},{value:"pink",image_src:""},{value:"white",image_src:""},{value:"blue",image_src:""},{value:"green",image_src:""},{value:"purple",image_src:""}, {value:"multicolor",image_src:""}],
    lookup={}


    }){
    const [hairStyleSelection,setHairStyleSelection] = useState(lookup.facial_hair_style)
    const [hairColorSelection,setHairColorSelection] = useState(lookup.facial_hair_color)
    const [progressAttempt, setProgressAttempt] = useState(false)

    const isDataGood = ()=>{
        return hairStyleSelection&&hairColorSelection;

    }

    const handleNext = onNext?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onNext({facial_hair_style:hairStyleSelection,facial_hair_color:hairColorSelection})
        }}:null



    const handlePrevious =  onPrevious?()=>{

        onPrevious({facial_hair_style:hairStyleSelection,facial_hair_color:hairColorSelection})



    }:null
    const handleDone = onComplete?()=>{
        setProgressAttempt(true)
        if(isDataGood()){
            onComplete({facial_hair_style:hairStyleSelection,facial_hair_color:hairColorSelection})
        }

    }:null
    return(
        <div>
            <ImageGridSelector onChange={(e)=>setHairStyleSelection(
                                e.currentTarget.value
                                )}
                                selectorName="Facial Hair Style"
                                flagHelp={!hairStyleSelection&&progressAttempt}
                                attributeOptions={facialHairType}
                                value={hairStyleSelection}
                                />

            <ImageGridSelector onChange={(e)=>setHairColorSelection(
                                e.currentTarget.value
                                )}
                                selectorName="Facial Hair Color"
                                flagHelp={!hairColorSelection&&progressAttempt}
                                attributeOptions={facialHairColor}
                                value={hairColorSelection}
                                />



        <ProgressButton onPrevious={handlePrevious} onNext={handleNext} onComplete={handleDone}/>
        </div>
    )

}
export default FacialHairSelection;
