import React from "react";
import styles from "../../styles/ChatView.module.css"


function ChatTypingAnimation({profile_name}){

    return (
        <div className={styles.ChatAnimationContainer}>

            <div className={styles.ChatAnimationCaption}>{`${profile_name} is typing...`}</div>
        </div>
    )

}

export default ChatTypingAnimation;
