import React from "react";
import UserProfile from "./UseProfile";
import { Link } from 'react-router-dom';


function NavBar({
    routes = [
        { name: "About", path: "/about", hideAt:"hidden sm:block" },
        { name: "Feed", path: "/feed" },
        { name: "Chat", path: "/chat", hideAt:"hidden md:block"},
        { name: "Profiles", path: "/profiles" },
        { name: "Gallery", path: "/gallery" },
        { name: "Settings", path: "/settings" },
    ],
}) {
    return (
        <ul className="flex justify-between items-center p-4 bg-gray-800">
            {/* Navbar items on the left */}
            <div className="flex space-x-4">
                {routes.map((val) => (
                    <li key={val.name}>
                        <Link
                            to={val.path}
                            className={`text-white ${val.hideAt} hover:text-gray-300 font-medium`}
                        >
                            {val.name}
                        </Link>
                    </li>
                ))}
            </div>

            {/* UserProfile on the right */}
            <li>
                <UserProfile />
            </li>
        </ul>
    );
}

export default NavBar;
